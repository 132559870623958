/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        // JavaScript to be fired on all pages
        var $primary_trigger = $('.js-primary');
        var $primary_nav = $('nav.nav-primary');
        $primary_trigger.click(function(e){
          e.preventDefault();
          $primary_nav.toggleClass( 'is-open' );
          $(this).toggleClass( 'is-open' );
        });

        var $mobile_trigger = $('.js-mobile-close');
        var $mobile_nav = $('.mobile-nav');
        var $mobile_trigger_open = $('.js-mobile');

        $mobile_trigger_open.click(function(){
          $mobile_nav.addClass('is-open');
        });

        $mobile_trigger.click(function(){
          $mobile_nav.removeClass('is-open');
        });

        var $menu_trigger = $('.mobile-nav-primary li.menu-item-has-children > a');
        var $menu_trigger_span = $('.mobile-nav-primary li.menu-item-has-children');
        $menu_trigger_span.append( '<span></span>' );

        var $sub_menu_trigger = $('.mobile-nav-primary li.menu-item-has-children span');

        $sub_menu_trigger.click( function(e){
          $(this).parent().toggleClass('is-open');
          $(this).siblings('.sub-menu').slideToggle();
        });

        lightbox.option({
          'resizeDuration': 200,
          'wrapAround': true
        });

        // slick
        if( $('div').hasClass('slider-banner') ) {
          $('.slider-banner').slick({
            autoplay: true,
            autoplaySpeed: 6000,
            arrows: false,
            dots: true,
          });
        }

        var $config = {
          arrows: true,
          dots: false,
          slidesToShow: 4,
          slidesToScroll: 4,
          responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                centerMode: false,
              }
            },
          ]
        }

        if( $('div').hasClass( 'module-product' ) || $('body').hasClass( 'single-product' ) ) {
          $('.module-product .products').slick( $config );
          $('.upsells .products').slick( $config );
          $('.related .products').slick( $config );
          $('.cross-sells .products').slick( $config );
        }

        // Single Page Quantity
        var $plus  = $('.plus');
        var $minus = $('.minus');

        $plus.click(function(){
          var $qty   = $( this ).siblings( '.js-qty' );
          var $val   = $qty.val();
          $qty.val( parseInt( $val ) + 1 );
          $('.cart-coupon .button').prop( 'disabled', false);
        });

        $minus.click(function(){
          var $qty   = $( this ).siblings( '.js-qty' );
          var $val   = $qty.val();

          if( parseInt( $val ) <= 1 ) {
            $qty.val(1);
          } else {
            $qty.val( parseInt( $val ) - 1 );
          }
          $('.cart-coupon .button').prop( 'disabled', false);
        });

        $('.shop-widget ul li.menu-item-has-children').append( '<span class="dropdown"></span>' );
        $('.shop-widget .dropdown').click(function(){
          $(this).toggleClass('is-open');
          $(this).siblings('ul.sub-menu').toggleClass('is-open');
          $('.current-menu-parent ul.sub-menu').removeClass( 'is-open' );
          $('.current-menu-item ul.sub-menu').removeClass( 'is-open' );
          $('.shop-widget li').removeClass('current-menu-parent');
          $('.shop-widget li').removeClass('current-menu-item');
        });

        $('.general-message .close').click(function(){
          $('.general-message').slideUp();
          $.cookie('closed', 'is_close', { expires: 1, path: '/' });
        });

        if (!$.cookie('closed')) {
         // no cookie
         $('.general-message').slideDown();
        }

        $('.mobile-widget-trigger').click(function(){
          $(this).toggleClass('is-open');
          $('.nav-shop').slideToggle();
        });

        $('.shop-widget .current-menu-parent .dropdown, .shop-widget .current-menu-item .dropdown').addClass('is-open');

        $bannerHeight = $('.banner').height();

        $(window).scroll(function(){
          if( scrollY > $bannerHeight ) {
            $('.banner, body').addClass('is-sticky');
            setTimeout(function(){
              $('.banner, body').addClass('is-fixed');
            }, 800);
          } else {
            $('.banner, body').removeClass('is-sticky');
            $('.banner, body').removeClass('is-fixed');
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
